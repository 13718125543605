import { useAppDispatch } from "@/store";
import { authActions, selectAuth } from "@/store/slices/auth";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "./forgot-password-form";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";

const AuthModal = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);

  return (
    <Modal
      show={authState.isModalOpen}
      onHide={() => dispatch(authActions.setIsModalOpen(false))}
      dialogClassName="modal-lg border-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999999999 }}
    >
      <Modal.Header
        className="p-0"
        closeButton
        onClick={() => dispatch(authActions.setIsModalOpen(false))}
      ></Modal.Header>
      <Modal.Body className="p-0">
        <div className="contact-box">
          {authState.activeTab == "login" && <LoginForm />}
          {authState.activeTab == "register" && <RegisterForm />}
          {authState.activeTab == "forgot-password" && <ForgotPasswordForm />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
